import { useLayoutEffect, useState } from 'react';

import type { Announcement } from '~/types/models';

import closeIcon from '~/public/assets/icons/x.svg';
import { createMarkup } from '~/utils/functions';

import classes from './Announcement.module.scss';

const storageKey = 'announcement-viewed';

const AnnouncementComponent = ({ content, updatedAt }: Announcement) => {
  const [isViewed, setIsViewed] = useState(false);

  useLayoutEffect(() => {
    if (localStorage.getItem(storageKey) === updatedAt) {
      setIsViewed(true);
    }
  }, [updatedAt]);

  const handleClose = () => {
    localStorage.setItem(storageKey, updatedAt);
    setIsViewed(true);
  };

  if (isViewed) {
    return null;
  }

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={classes.content} dangerouslySetInnerHTML={createMarkup(content)} />
      {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <img
        src={closeIcon.src}
        alt="Close announcement"
        width={18}
        height={18}
        onClick={handleClose}
      />
    </div>
  );
};

export default AnnouncementComponent;
